import React from 'react';
import { HeaderProps } from './header.definition';
import { HeaderNavigation } from './_partials/header-navigation/header-navigation.component';

import * as S from './header.styles';

export function Header({ className, title, navigation }: HeaderProps) {
  return (
    <S.Header className={className}>
      <div className="title">{title}</div>
      {navigation && <HeaderNavigation {...navigation} />}
    </S.Header>
  );
}
