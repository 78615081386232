import React from 'react';

export function IconEdit(): JSX.Element {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
    >
      <path d="M21.724 3.724l-18 18c-0.156 0.155-0.28 0.357-0.344 0.592l-2 7.333c-0.061 0.217-0.065 0.461 0 0.701 0.193 0.711 0.927 1.129 1.637 0.936l7.333-2c0.212-0.056 0.42-0.172 0.592-0.344l18-18c0.996-0.996 1.495-2.305 1.495-3.609s-0.499-2.613-1.495-3.609-2.305-1.495-3.609-1.495-2.613 0.499-3.609 1.495zM23.609 5.609c0.476-0.476 1.099-0.713 1.724-0.713s1.248 0.237 1.724 0.715 0.713 1.097 0.713 1.723-0.237 1.248-0.713 1.724l-17.749 17.749-4.741 1.293 1.293-4.74z" />
    </svg>
  );
}
