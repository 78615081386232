import React from 'react';
import ReactDOMServer from 'react-dom/server';
import { v4 as uuid } from 'uuid';

import { IconOptions } from '../icon.definition';

export function IconChecked({ fillColor }: IconOptions): JSX.Element {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
    >
      <path
        fill={fillColor}
        d="M28 0h-24c-2.2 0-4 1.8-4 4v24c0 2.2 1.8 4 4 4h24c2.2 0 4-1.8 4-4v-24c0-2.2-1.8-4-4-4zM14 24.828l-7.414-7.414 2.828-2.828 4.586 4.586 9.586-9.586 2.828 2.828-12.414 12.414z"
      />
    </svg>
  );
}

export default function Checked({ fillColor }: IconOptions): string {
  const renderedIcon = ReactDOMServer.renderToStaticMarkup(
    <IconChecked id={uuid()} fillColor={fillColor} />
  );
  return `data:image/svg+xml;utf8,${renderedIcon}`;
}
