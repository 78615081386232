import config from 'src/styles/style.config.json';

import styled from 'styled-components';
import { darken } from 'polished';
import { config as buttonConfig } from './button.config';

import { ButtonStyledProps } from './button.definition';

export const Button = styled.button<ButtonStyledProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: ${config.font.family.regular};
  font-weight: ${config.font.weight.regular};
  font-size: 0.8rem;
  color: ${({ theme }: Pick<ButtonStyledProps, 'theme'>) =>
    buttonConfig[theme].content};
  width: ${({ shape }) => shape === 'square' && '48px'};
  background-color: ${({ theme }: Pick<ButtonStyledProps, 'theme'>) =>
    buttonConfig[theme].background};
  padding: 0.8rem;
  border: 0;
  border-radius: ${({ shape }) =>
    shape === 'circle' ? '50%' : `${config.border.radius.desktop / 2}rem`};
  margin: ${({ inToolbar }) => (inToolbar ? '0 0 0 0.8rem' : 0)};
  outline: 0;
  opacity: 1;
  cursor: ${({ disableHover }) => (disableHover ? 'default' : 'pointer')};
  transition: background-color 150ms;

  @media (min-width: ${config.breakpoints.small_tablet}) {
    width: ${({ shape }) => shape === 'square' && '70px'};
    font-size: 1rem;
  }

  @media (min-width: ${config.breakpoints.desktop}) {
    font-size: 1.2rem;
  }

  svg {
    width: 23px;
    height: 23px;

    path {
      fill: ${({ theme }: Pick<ButtonStyledProps, 'theme'>) =>
        buttonConfig[theme].content};
    }

    @media (min-width: ${config.breakpoints.small_tablet}) {
      display: block;
    }
  }

  &:hover,
  &:disabled {
    background-color: ${({
      disableHover,
      theme,
    }: Pick<ButtonStyledProps, 'disableHover' | 'theme'>) =>
      disableHover
        ? buttonConfig[theme].background
        : darken(0.08, buttonConfig[theme].background)};
  }

  &:disabled {
    cursor: default;
  }
`;

export const Children = styled.span<Pick<ButtonStyledProps, 'icon'>>`
  display: inline-block;
  font-size: 0.8rem;
  margin-right: 0;

  @media (min-width: ${config.breakpoints.small_tablet}) {
    font-size: 1rem;
    margin-right: ${({ icon }) => (icon ? '0.8rem' : 0)};
  }

  @media (min-width: ${config.breakpoints.tablet}) {
    font-size: 1.2rem;
  }
`;
