import React from 'react';

export function IconBin(): JSX.Element {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
    >
      <path d="M24 10v17.333c0 0.368-0.148 0.7-0.391 0.943s-0.575 0.391-0.943 0.391h-13.333c-0.368 0-0.7-0.148-0.943-0.391s-0.391-0.575-0.391-0.943v-17.333zM22.667 7.333v-1.333c0-1.104-0.449-2.107-1.172-2.828s-1.724-1.172-2.828-1.172h-5.333c-1.104 0-2.107 0.449-2.828 1.172s-1.172 1.724-1.172 2.828v1.333h-5.333c-0.736 0-1.333 0.597-1.333 1.333s0.597 1.333 1.333 1.333h1.333v17.333c0 1.104 0.449 2.107 1.172 2.828s1.724 1.172 2.828 1.172h13.333c1.104 0 2.107-0.449 2.828-1.172s1.172-1.724 1.172-2.828v-17.333h1.333c0.736 0 1.333-0.597 1.333-1.333s-0.597-1.333-1.333-1.333zM12 7.333v-1.333c0-0.368 0.148-0.7 0.391-0.943s0.575-0.391 0.943-0.391h5.333c0.368 0 0.7 0.148 0.943 0.391s0.391 0.575 0.391 0.943v1.333z" />
    </svg>
  );
}
