import React from 'react';

export function IconDown(): JSX.Element {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
    >
      <path d="M7.057 13.609l8 8c0.521 0.521 1.365 0.521 1.885 0l8-8c0.521-0.521 0.521-1.365 0-1.885s-1.365-0.521-1.885 0l-7.057 7.057-7.057-7.057c-0.521-0.521-1.365-0.521-1.885 0s-0.521 1.365 0 1.885z" />
    </svg>
  );
}
