import { ButtonConfig } from './button.definition';

export const config: ButtonConfig = {
  default: {
    background: '#f1f1f1',
    content: '#686868',
  },
  error: {
    background: '#ff3d3d',
    content: '#ffe7e2',
  },
  new: {
    background: '#1960ff',
    content: '#ffffff',
  },
  pending: {
    background: '#ffead0',
    content: '#ad6a2f',
  },
  success: {
    background: '#d0ffd3',
    content: '#2fad34',
  },
  delete: {
    background: '#ffe7e2',
    content: '#ff3d3d',
  },
  theme: {
    background: '#a1beff',
    content: '#1960ff',
  },
};
