import config from 'src/styles/style.config.json';
import styled from 'styled-components';

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 3rem;
  padding: 0 1rem;
  background-color: ${config.theme.primary};
  color: #fff;
  font-family: ${config.font.family.regular};
  font-weight: ${config.font.family.regular};
  font-size: 0.8rem;

  @media (min-width: ${config.breakpoints.tablet}) {
    font-size: 1rem;
    height: 3.75rem;
  }

  .title {
    margin: 1rem 0;
    font-family: ${config.font.family.bold};
    font-weight: ${config.font.weight.bold};
  }
`;
