import React from 'react';

export function IconTick(): JSX.Element {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
    >
      <path d="M25.724 7.724l-13.724 13.724-5.724-5.724c-0.521-0.521-1.365-0.521-1.885 0s-0.521 1.365 0 1.885l6.667 6.667c0.521 0.521 1.365 0.521 1.885 0l14.667-14.667c0.521-0.521 0.521-1.365 0-1.885s-1.365-0.521-1.885 0z" />
    </svg>
  );
}
