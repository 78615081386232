import React, { createElement } from 'react';

import { IconMap, IconProps } from './icon.definition';

import { IconAdd } from './_partials/add';
import { IconBin } from './_partials/bin';
import { IconChecked } from './_partials/checked';
import { IconDown } from './_partials/down';
import { IconEdit } from './_partials/edit';
import { IconEmail } from './_partials/email';
import { IconHide } from './_partials/hide';
import { IconMenu } from './_partials/menu';
import { IconPhone } from './_partials/phone';
import { IconSearch } from './_partials/search';
import { IconTick } from './_partials/tick';
import { IconUnchecked } from './_partials/unchecked';
import { IconView } from './_partials/view';

import * as S from './icon.styles';

export const iconMap: IconMap = {
  add: IconAdd,
  bin: IconBin,
  checked: IconChecked,
  down: IconDown,
  edit: IconEdit,
  email: IconEmail,
  hide: IconHide,
  menu: IconMenu,
  phone: IconPhone,
  search: IconSearch,
  tick: IconTick,
  unchecked: IconUnchecked,
  view: IconView,
};

export function Icon({ className, icon, options }: IconProps): JSX.Element {
  return (
    <S.Icon className={className}>
      {createElement(iconMap[icon], options)}
    </S.Icon>
  );
}
