import React from 'react';

export function IconAdd(): JSX.Element {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
    >
      <path d="M6.667 18h8v8c0 0.736 0.597 1.333 1.333 1.333s1.333-0.597 1.333-1.333v-8h8c0.736 0 1.333-0.597 1.333-1.333s-0.597-1.333-1.333-1.333h-8v-8c0-0.736-0.597-1.333-1.333-1.333s-1.333 0.597-1.333 1.333v8h-8c-0.736 0-1.333 0.597-1.333 1.333s0.597 1.333 1.333 1.333z" />
    </svg>
  );
}
