import React, { FC } from 'react';
import { v4 as uuid } from 'uuid';

import { Icon } from 'components/icon/icon.component';

import { ButtonProps } from './button.definition';

import * as S from './button.styles';

export const Button: FC<ButtonProps> = ({
  className,
  theme,
  inToolbar,
  icon,
  shape,
  disableHover,
  children,
  disabled,
  onClick,
  type,
  name,
}: ButtonProps) => {
  const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    if (typeof onClick === 'function') onClick();
  };

  return (
    <S.Button
      className={className}
      disabled={disabled}
      disableHover={disableHover}
      icon={icon}
      inToolbar={inToolbar}
      onClick={(e) => handleClick(e)}
      shape={shape}
      type={type}
      theme={theme}
      name={name}
    >
      {children && <S.Children>{children}</S.Children>}
      {/* REVIEW: Pass Icon as children instead? */}
      {icon && <Icon icon={icon} options={{ id: uuid() }} />}
    </S.Button>
  );
};

export const ButtonFieldDefinition = {
  type: 'button',
  component: Button,
};
