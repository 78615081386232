import Link from 'next/link';
import { HeaderNavigationProps } from './header-navigation.definition';

import * as S from './header-navigation.styles';

export function HeaderNavigation(props: HeaderNavigationProps) {
  return (
    <S.HeaderNavigation className={props?.className}>
      <ul>
        {/* {identity && (
          <Fragment>
            <li>
              <Link href={`/user/${user.id}`}>
                <a>Profile</a>
              </Link>
            </li>
          </Fragment>
        )} */}
        <LoginLogout {...props} />
      </ul>
    </S.HeaderNavigation>
  );
}

function LoginLogout({ path, user }: HeaderNavigationProps) {
  if (['/', '/login'].includes(path)) return null;

  if (user) {
    return (
      <li>
        <Link href="/logout">
          <a>Logout</a>
        </Link>
      </li>
    );
  }

  return (
    <li>
      <Link href="/login">
        <a>Login</a>
      </Link>
    </li>
  );
}
