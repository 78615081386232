import config from 'src/styles/style.config.json';
import styled from 'styled-components';

export const HeaderNavigation = styled.nav`
  ul {
    list-style: none;
    margin-left: 0;
    padding-left: 0;
    margin-bottom: 1rem;

    li {
      display: inline-block;
      margin-right: 1rem;
      margin-bottom: 0;

      &:last-child {
        margin-right: auto;
      }

      a {
        display: inline-block;
        text-decoration: none;
        cursor: pointer;
        font-size: 0.8rem;
        color: ${config.font.color.secondary};
        padding-top: 0.1025rem;
        padding-bottom: 0;

        &.active {
          border-bottom: 0.25rem solid ${config.theme.primary};
        }

        @media (min-width: ${config.breakpoints.tablet}) {
          font-size: 1rem;
          padding-top: 0;
        }
      }
    }
  }
`;
