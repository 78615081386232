import React from 'react';

export function IconHide(): JSX.Element {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
    >
      <path d="M13.504 7.619c0.868-0.203 1.728-0.295 2.48-0.285 2.344 0 4.412 0.745 6.211 1.857 1.492 0.923 2.785 2.089 3.859 3.264 0.859 0.94 1.567 1.873 2.113 2.668 0.436 0.633 0.76 1.165 0.977 1.54-0.728 1.271-1.547 2.428-2.371 3.389-0.479 0.559-0.415 1.401 0.144 1.88s1.401 0.415 1.88-0.144c1.091-1.272 2.148-2.816 3.044-4.493 0.197-0.372 0.217-0.824 0.017-1.225 0 0-0.528-1.052-1.493-2.457-0.601-0.875-1.384-1.909-2.343-2.957-1.192-1.305-2.672-2.649-4.425-3.733-2.127-1.313-4.675-2.255-7.581-2.255-0.979-0.012-2.053 0.105-3.12 0.355-0.717 0.168-1.163 0.885-0.995 1.603s0.885 1.163 1.603 0.995zM13.369 15.923l3.375 3.375c-0.243 0.081-0.496 0.125-0.751 0.135-0.684 0.024-1.373-0.212-1.912-0.715s-0.823-1.173-0.847-1.857c-0.011-0.317 0.033-0.635 0.135-0.939zM7.977 10.529l3.404 3.404c-0.579 0.921-0.848 1.979-0.811 3.021 0.048 1.363 0.617 2.711 1.695 3.713s2.461 1.476 3.824 1.428c0.923-0.032 1.839-0.304 2.645-0.811l3.096 3.096c-1.837 1.065-3.86 1.595-5.845 1.619-2.312 0-4.38-0.745-6.179-1.857-1.492-0.923-2.785-2.089-3.859-3.264-0.859-0.94-1.567-1.873-2.113-2.668-0.435-0.633-0.76-1.164-0.976-1.539 1.4-2.429 3.168-4.505 5.121-6.143zM0.391 2.943l5.695 5.695c-2.308 1.972-4.359 4.477-5.927 7.399-0.197 0.372-0.219 0.825-0.017 1.227 0 0 0.528 1.052 1.493 2.457 0.601 0.875 1.384 1.909 2.343 2.957 1.192 1.305 2.672 2.649 4.425 3.733 2.127 1.315 4.675 2.256 7.613 2.256 2.657-0.032 5.359-0.801 7.753-2.345l5.955 5.955c0.521 0.521 1.365 0.521 1.885 0s0.521-1.365 0-1.885l-11.835-11.835c-0.003-0.003-0.007-0.007-0.009-0.009l-5.612-5.613c-0.011-0.009-0.021-0.021-0.032-0.032l-11.845-11.844c-0.521-0.521-1.365-0.521-1.885 0s-0.521 1.365 0 1.885z" />
    </svg>
  );
}
