// TODO: Store in DB
export default {
  title: 'Chancery Contracts Limited',
  navigation: [
    {
      href: '/customers',
      title: 'Customers',
    },
    {
      href: '/sites',
      title: 'Sites',
    },
    {
      href: '/jobs',
      title: 'Jobs',
    },
    {
      href: '/users',
      title: 'Users',
    },
  ],
};
