import React from 'react';

export function IconView(): JSX.Element {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
    >
      <path d="M0.141 16.071c-0.181 0.365-0.195 0.804 0 1.192 0 0 0.528 1.052 1.493 2.457 0.601 0.875 1.384 1.909 2.343 2.957 1.192 1.305 2.672 2.649 4.425 3.733 2.127 1.315 4.675 2.256 7.597 2.256s5.471-0.941 7.597-2.256c1.753-1.084 3.233-2.428 4.425-3.733 0.957-1.048 1.741-2.083 2.343-2.957 0.965-1.405 1.493-2.457 1.493-2.457 0.181-0.365 0.195-0.804 0-1.192 0 0-0.528-1.052-1.493-2.457-0.601-0.875-1.384-1.909-2.343-2.957-1.192-1.305-2.672-2.649-4.425-3.733-2.127-1.315-4.675-2.256-7.597-2.256s-5.471 0.941-7.597 2.256c-1.753 1.084-3.233 2.428-4.425 3.733-0.959 1.048-1.741 2.081-2.343 2.957-0.965 1.405-1.493 2.457-1.493 2.457zM2.853 16.667c0.217-0.375 0.543-0.908 0.979-1.544 0.547-0.795 1.253-1.728 2.113-2.668 1.073-1.175 2.367-2.341 3.859-3.264 1.8-1.112 3.868-1.857 6.196-1.857s4.396 0.745 6.195 1.857c1.492 0.923 2.785 2.089 3.859 3.264 0.859 0.94 1.567 1.873 2.113 2.668 0.437 0.636 0.763 1.168 0.979 1.544-0.217 0.375-0.543 0.908-0.979 1.544-0.547 0.795-1.253 1.728-2.113 2.668-1.073 1.175-2.367 2.341-3.859 3.264-1.799 1.112-3.867 1.857-6.195 1.857s-4.396-0.745-6.195-1.857c-1.492-0.923-2.785-2.089-3.859-3.264-0.859-0.94-1.567-1.873-2.113-2.668-0.437-0.636-0.763-1.169-0.98-1.544zM21.333 16.667c0-1.472-0.599-2.808-1.563-3.771s-2.299-1.563-3.771-1.563-2.808 0.599-3.771 1.563-1.563 2.299-1.563 3.771 0.599 2.808 1.563 3.771 2.299 1.563 3.771 1.563 2.808-0.599 3.771-1.563 1.563-2.299 1.563-3.771zM18.667 16.667c0 0.737-0.297 1.401-0.781 1.885s-1.148 0.781-1.885 0.781-1.401-0.297-1.885-0.781-0.781-1.148-0.781-1.885 0.297-1.401 0.781-1.885 1.148-0.781 1.885-0.781 1.401 0.297 1.885 0.781 0.781 1.148 0.781 1.885z" />
    </svg>
  );
}
