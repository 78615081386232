export const strFirstToUpper = (str: string): string => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const strToBoolean = (strBool: string | boolean): boolean => {
  if (typeof strBool === 'string') {
    if (strBool.toLowerCase() === 'true') return true;
    if (strBool.toLowerCase() === 'false') return false;
  } else if (typeof strBool === 'boolean') {
    return strBool;
  }

  return false;
};
